import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=efb37f60&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Link: require('/usr/app/components/link/Link.vue').default,SocialAccounts: require('/usr/app/components/socialAccounts/SocialAccounts.vue').default,Cookie: require('/usr/app/components/cookie/Cookie.vue').default,Footer: require('/usr/app/components/footer/Footer.vue').default})
