
import site_settings from "@/static/site-settings.json";
import { gsap } from "gsap";
import ScrollToPlugin from "gsap/dist/ScrollToPlugin.js";
import ScrollTrigger from "gsap/dist/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export default {
  name: "Header",
  data() {
    return {
      menuText: 'MENU',
      getInTouchText: 'Get in Touch',
      header: site_settings.headerSettings,
      contactUs: "",
      isEntrance: true,
      codeInjection: site_settings.headerSettings.codeInjection,
    };
  },
  async fetch() {
    const { data: { slug: contactUs } } = await this.$axios.get(`/contact-us?_locale=en`);
    this.contactUs = contactUs;
  },
  mounted() {
    this.headScript();
    this.resize();
    window.addEventListener("resize", this.resize);
    const header = this.$refs["header"];
    const toggler = document.querySelector(".navbar-toggler");
    ScrollTrigger.create({
      trigger: "body",
      start: "20px top",
      end: "+=50000 bottom",
      onEnter: () => {
        header.classList.add("header-fixed");
      },
      onLeaveBack: () => {
        header.classList.remove("header-fixed");
        toggler.ariaExpanded = false;
      },
    });
  },
  methods: {
    async headScript() {
      if (this.codeInjection.length > 0) {
        document.head.insertAdjacentHTML('afterbegin', this.codeInjection);
      }
    },
    toggle(e) {
      const body = document.querySelector("body");
      if (e.target.ariaExpanded) body.classList.toggle("menu-open");
    },
    resize() {
      const body = document.querySelector("body");
      const toggler = document.querySelector(".navbar-toggler");
      body.classList.remove("menu-open");
      toggler.ariaExpanded = false;
    },
    enter() {
      const body = document.querySelector("body");
      sessionStorage.setItem("ai", false);
      this.isEntrance = false;
      body.classList.remove("intro-not-entrance");
      body.classList.remove("no-scroll");
    },
  },
};
