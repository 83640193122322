
import site_settings from "@/static/site-settings.json";

export default {
  name: "SocialAccounts",
  data() {
    return {
      socialAccounts: site_settings.socialMediaAccounts,
    };
  },
};
