
export default {
  name: "error",
  layout: "default",
  props: {
    error: {
      type: Object,
      default: () => {
        "error";
      },
    },
  },
};
