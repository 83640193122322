
export default {
  name: "Picture",
  props: {
    image: {
      type: Object,
      default() { },
    },
    ratio: {
      type: String,
      default: "1-1",
    },
  },
};
