
// import lang from "~/mixins/lang.js";
import site_settings from "@/static/site-settings.json";

export default {
  name: "Footer",
  // mixins: [lang],
  data() {
    return {
      footer: {},
      cookie: {},
      codeInjection: "",
    };
  },
  created() {
    this.footer = site_settings.footerSettings;
    this.cookie = site_settings.cookieNotification;
    this.codeInjection = site_settings.footerSettings.codeInjection;
  },
  mounted() {
    this.bodyScript();
  },
  methods: {
    async bodyScript() {
      await this.codeInjection;
      if (this.codeInjection && this.codeInjection.length > 0) {
        document.body.insertAdjacentHTML("afterbegin", this.codeInjection);
      }
    },
  },
};
