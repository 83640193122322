
export default {
  props: {
    cookie: {
      type: Object,
      default() { },
    },
  },
  data() {
    return {
      cookieStorage: false,
      cookieDisplay: false,
    };
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 100 && this.cookieStorage) this.cookieDisplay = true;
    },
    async getData() {
      try {
        // Storage
        this.cookieStorage = localStorage.getItem("alhlc");

        switch (this.cookieStorage) {
          case null:
            localStorage.setItem("alhlc", true);
            this.cookieStorage = true;
            break;
          case true:
            this.cookieStorage = true;
            break;
          default:
            this.cookieStorage = false;
            break;
        }
      } catch (err) {
        return false;
      }
    },
    close() {
      localStorage.setItem("alhlc", "false");
      this.cookieStorage = false;
    },
  },
};
